import { gsap } from 'gsap';

/* eslint-disable no-new */
import TypingText from '../components/animations/typing_text';
import DrawingStrokes from '../components/animations/drawing_stroke';

const paths = document.querySelectorAll('.anim-stroke');
if (paths) new DrawingStrokes(paths);

new TypingText();

class Home {
  // eslint-disable-next-line no-useless-constructor,no-empty-function
  constructor() {
    this.dom = {
      bannerCircleLeft: document.getElementById('banner-circle-left'),
      bannerCircleRight: document.getElementById('banner-circle-right'),
      bannerCaption: document.getElementById('banner-caption'),
    };

    this.mount();
  }

  mount() {
    gsap.from(this.dom.bannerCircleLeft, {
      x: '200%', opacity: 0, duration: 1.5, delay: 0, ease: 'circ.out',
    });
    gsap.from(this.dom.bannerCircleRight, {
      x: '-200%', opacity: 0, duration: 1.5, delay: 0, ease: 'circ.out',
    });
    gsap.from(this.dom.bannerCaption, {
      opacity: 0, duration: 1, delay: 1, ease: 'circ.out',
    });
  }
}

if (document.getElementById('page-home')) {
  // eslint-disable-next-line no-new
  new Home();
}
