import { throttle } from 'lodash';

export default class Cursor {
  constructor(cursor) {
    this.dom = {
      cursor,
    };

    this.mount();
  }

  mount() {
    document.addEventListener('mousemove', throttle((e) => this.mouseMove(e), 100));
  }

  // eslint-disable-next-line class-methods-use-this
  mouseMove(e) {
    const x = e.clientX;
    const y = e.clientY;
    this.dom.cursor.style.transform = `translate3d(calc(${x}px - 50%), calc(${y}px - 50%), 0)`;
    this.dom.cursor.style.opacity = '1';
  }
}
