/* eslint-disable no-new */
import Menu from '../components/menu/menu';
import FadeIn from '../components/animations/fade_in';
import Cursor from '../components/cursor/cursor';

const siteNavMenuOverlay = document.getElementById('site-nav-menu');
if (siteNavMenuOverlay) new Menu(siteNavMenuOverlay);

const animElements = document.querySelectorAll('.js-anim');
if (animElements) new FadeIn(animElements);

const cursor = document.querySelector('.cursor');
if (cursor) new Cursor(cursor);

function requireAll(r) {
  r.keys().forEach(r);
}

// load all js files, but exclude those in partials
requireAll(require.context('./', true, /^(?!.*\/partials\/).*.js$/));
