export default class MenuItem {
  constructor(menuItem, options = {}) {
    this.defaultOptions = {};
    this.options = { ...this.defaultOptions, ...options };

    this.dom = {
      menuItem,
      line: menuItem.querySelector('.line'),
      chars: menuItem.querySelectorAll('.char'),
      lineClone: '',
      lineCloneChars: undefined,
    };

    this.data = {
      menuItemEnter: new Event('menuItemEnter'),
      menuItemLeave: new Event('menuItemLeave'),
      timelineText: undefined,
      timelineTextDefaults: {
        defaults: {
          duration: 0.5,
          ease: 'power2.inOut',
          stagger: {
            amount: 0.1,
          },
        },
      },
    };

    this.mount();
  }

  mount() {
    this.dom.menuItem.addEventListener('mouseenter', () => this.onMouseEnter());
    this.dom.menuItem.addEventListener('mouseleave', () => this.onMouseLeave());
  }

  onMouseEnter() {
    if (this.dom.menuItem.dataset.img) this.dom.menuItem.dispatchEvent(this.data.menuItemEnter);
  }

  onMouseLeave() {
    if (this.dom.menuItem.dataset.img) this.dom.menuItem.dispatchEvent(this.data.menuItemLeave);
  }
}
