import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);

export default class DrawingStroke {
  constructor(paths) {
    this.dom = {
      paths,
    };

    this.mount();
  }

  // eslint-disable-next-line class-methods-use-this
  mount() {
    this.dom.paths.forEach((path) => {
      gsap.fromTo(
        path,
        { drawSVG: '0 0' },
        {
          duration: 1,
          drawSVG: '0% 100%',
          ease: 'Power2.easeOut',
          scrollTrigger: {
            trigger: path,
            scrub: 5,
            start: 'bottom 100%',
            end: 'end 75%',
            markers: false,
          },
        },
      );
    });
  }
}
