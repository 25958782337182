export default class TypingText {
  constructor() {
    this.dom = {
      typedTextSpan: document.querySelector('.typed-text'),
    };

    this.data = {
      textArray: [...document.querySelectorAll('[data-title]')].map((el) => el.dataset.title),
      textArrayIndex: 0,
      charIndex: 0,
    };

    this.components = {};

    this.settings = {
      typingDelay: 100,
      erasingDelay: 50,
      newTextDelay: 1000,
    };

    this.mount();
  }

  mount() {
    if (this.dom.typedTextSpan) {
      if (this.data.textArray.length) setTimeout(() => this.type(), this.settings.newTextDelay + 250);
    }
  }

  type() {
    if (this.data.charIndex < this.data.textArray[this.data.textArrayIndex].length) {
      this.dom.typedTextSpan.textContent += this.data.textArray[this.data.textArrayIndex].charAt(this.data.charIndex);
      this.data.charIndex += 1;
      setTimeout(() => this.type(), this.settings.typingDelay);
    } else {
      setTimeout(() => this.erase(), this.settings.newTextDelay);
    }
  }

  erase() {
    if (this.data.charIndex > 0) {
      this.dom.typedTextSpan.textContent = this.data.textArray[this.data.textArrayIndex].substring(0, this.data.charIndex - 1);
      this.data.charIndex -= 1;
      setTimeout(() => this.erase(), this.settings.erasingDelay);
    } else {
      this.data.textArrayIndex += 1;
      if (this.data.textArrayIndex >= this.data.textArray.length) this.data.textArrayIndex = 0;
      setTimeout(() => this.type(), this.settings.typingDelay + 1100);
    }
  }
}
