import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class FadeIn {
  constructor(elements) {
    this.dom = {
      elements,
    };

    this.mount();
  }

  mount() {
    this.dom.elements.forEach((element) => {
      gsap.fromTo(element, {
        autoAlpha: 0,
      }, {
        scrollTrigger: {
          trigger: element,
          once: true,
          markers: false,
          start: 'top 90%',
          end: 'end 50%',
        },
        duration: (element.dataset.animSpeed == null) ? 1 : element.dataset.animSpeed,
        autoAlpha: (element.dataset.animOpacity == null) ? 1 : element.dataset.animOpacity,
      });
    });
  }
}
