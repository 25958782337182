import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import MenuItem from './menu_item';

gsap.registerPlugin(SplitText);

export default class Menu {
  constructor(menu) {
    this.dom = {
      menu,
      html: document.documentElement,
      toggles: document.querySelectorAll('[data-toggle="site-nav-menu"]'),
      primaryLinks: menu.querySelectorAll('.nav-primary .nav-link'),
      secondaryLinks: menu.querySelectorAll('.nav-secondary .nav-link'),
      splitPrimaryLinks: menu.querySelectorAll('.nav-primary .split-text'),
      splitSecondaryLinks: menu.querySelectorAll('.nav-secondary .split-text'),
      menuImageBack: menu.querySelector('.menu-img'),
      menuImageFront: menu.querySelector('.menu-img-front'),
      splitText: {
        primary: undefined,
        secondary: undefined,
      },
    };

    this.states = {
      STATE_OPEN: 'open',
      STATE_CLOSED: 'closed',
      ACTIVE_STATE: 'closed',
    };

    this.data = {
      classNames: {
        html: 'site-menu-open',
        toggle: 'is-active',
        return: 'is-active',
      },
      activeImage: undefined,
      timelineImage: undefined,
      timeline: gsap.timeline(),
      menuItemInstances: [],
    };

    // set active image state
    if (this.dom.menuImageBack) {
      this.data.activeImage = this.dom.menuImageBack.src;
    }

    this.mount();
  }

  mount() {
    this.splitText();

    // create menu item instances
    [...this.dom.primaryLinks, ...this.dom.secondaryLinks].forEach((link) => {
      this.data.menuItemInstances.push(new MenuItem(link));

      if (link.dataset.img && this.hasImage()) {
        link.addEventListener('menuItemEnter', (e) => this.menuItemEnter(e));
        link.addEventListener('menuItemLeave', (e) => this.menuItemLeave(e));
      }
    });

    // Add click handlers
    this.dom.toggles.forEach((toggle) => {
      toggle.addEventListener('click', () => this.onToggleClick());
    });
  }

  splitText() {
    this.dom.splitText.primary = new SplitText(this.dom.splitPrimaryLinks, {
      type: 'chars, lines',
      charsClass: 'char',
      linesClass: 'line',
    });

    this.dom.splitText.secondary = new SplitText(this.dom.splitSecondaryLinks, {
      type: 'chars, lines',
      charsClass: 'char',
      linesClass: 'line',
    });
  }

  hasImage() {
    return !(!this.dom.menuImageBack || !this.dom.menuImageFront);
  }

  setState(state) {
    switch (state) {
      case this.states.STATE_OPEN:
        this.states.ACTIVE_STATE = this.states.STATE_OPEN;
        this.onShow();
        break;
      case this.states.STATE_CLOSED:
        this.states.ACTIVE_STATE = this.states.STATE_CLOSED;
        this.onHide();
        break;
      default:
        break;
    }
  }

  onToggleClick() {
    if (this.states.ACTIVE_STATE === this.states.STATE_OPEN) {
      this.setState(this.states.STATE_CLOSED);
    } else {
      this.setState(this.states.STATE_OPEN);
    }
  }

  onShow() {
    // add html class
    this.dom.html.classList.add(this.data.classNames.html);

    // add toggles class
    this.dom.toggles.forEach((toggle) => {
      toggle.classList.add(this.data.classNames.toggle);
    });

    this.animateIn();
  }

  onHide() {
    this.animateOut();
  }

  resetProps() {
    gsap.set(this.dom.menu, { clearProps: 'opacity' });
    gsap.set(this.dom.menuImageBack, { clearProps: 'opacity,scale' });
    gsap.set(
      [...this.dom.splitText.primary.chars, ...this.dom.splitText.secondary.chars],
      { clearProps: 'opacity,skewX,rotationX' },
    );
  }

  // menu build up animation
  animateIn() {
    this.resetProps();

    this.data.timeline
      .clear()
      .from(this.dom.menu, {
        duration: 0.25,
        opacity: 0,
        ease: 'none',
      })
      .addLabel('menuVisible')
      .from(this.dom.splitText.primary.chars, {
        opacity: 0,
        rotationX: '-60deg',
        skewX: '6deg',
        ease: 'power2.out',
        stagger: {
          amount: 0.3,
        },
      }, 'menuVisible-=0.1')
      .from(this.dom.splitText.secondary.chars, {
        opacity: 0,
        rotationX: '-40deg',
        skewX: '4deg',
        ease: 'power2.out',
        stagger: {
          amount: 0.175,
        },
      }, 'menuVisible+=0.2')
      .from(this.dom.menuImageBack, {
        duration: 0.5,
        scale: 1.2,
        opacity: 0,
        ease: 'none',
      }, 'menuVisible+=0.1');
  }

  // menu build out animation
  animateOut() {
    this.data.timeline
      .clear()
      .to([this.dom.menuImageBack, ...this.dom.splitText.primary.chars, ...this.dom.splitText.secondary.chars], {
        duration: 0.2,
        opacity: 0,
        ease: 'none',
      })
      .to(this.dom.menu, {
        duration: 0.2,
        opacity: 0,
        ease: 'none',
        onComplete: () => {
          // remove html class
          this.dom.html.classList.remove(this.data.classNames.html);
          // remove toggles class
          this.dom.toggles.forEach((toggle) => {
            toggle.classList.remove(this.data.classNames.toggle);
          });

          // reset props
          this.resetProps();
        },
      });
  }

  /**
   * on menu item mouse enter
   * @param e {MouseEvent}
   */
  menuItemEnter(e) {
    const targetPath = e.target.dataset.img;

    // front and back image are the same, do nothing
    if (targetPath === this.dom.menuImageBack.src) return;

    // kill existing animations
    if (this.data.timelineImage) this.data.timelineImage.kill();

    // set to initial value
    gsap.set(this.dom.menuImageFront, { clearProps: 'all' });

    // set front image path to target path
    this.dom.menuImageFront.src = targetPath;

    // animate
    this.data.timelineImage = gsap.timeline()
      .to(this.dom.menuImageFront, {
        duration: 0.4,
        opacity: 1,
        scale: 1,
        ease: 'power1.inOut',
        onComplete: () => {
          this.dom.menuImageBack.src = this.dom.menuImageFront.src;
        },
      });
  }

  /**
   * on menu item mouse leave
   * @param e {MouseEvent}
   */
  menuItemLeave(e) {
    const targetPath = e.target.dataset.img;

    // front and back image are the same, do nothing
    if (targetPath === this.dom.menuImageBack.src) return;

    // set back image equal to front image
    this.dom.menuImageBack.src = this.dom.menuImageFront.src;
  }
}
